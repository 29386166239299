import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import logo from "../assets/images/solutions-logo.png";
import businessLogo from "../assets/images/bbb-logo.png";
import Container from "components/Container";

const Header = () => {
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      style={{
        backgroundColor: navBackground ? "white" : "transparent",
        boxShadow: navBackground ? "3px 3px 6px #00000026" : "none",
        transition: navBackground
          ? "all 0.2s cubic-bezier(0, 0.865, 0.535, 1)"
          : "none",
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: "9999"
      }}
    >
      <Container>
        <header className="nav">
          <Link to="/">
            <img src={logo} className="nav__logo" />
          </Link>
          <ul>
            <li className="nav__link">
              <Link to="/">Home</Link>
            </li>
            <li className="nav__link">
              <Link to="/policy/">Privacy Policy</Link>
            </li>
            <li className="nav__link">
              <img src={businessLogo} className="nav__business-logo" />
            </li>
          </ul>
        </header>
      </Container>
    </div>
  );
};

export default Header;
